<style scoped>

</style>

<template>
  <v-container>
    <HyphenDatatable
    :headers="headers"
    :config="{resource:'on-site-consultation/supervisor', title: 'On Site Consultations', rowClickUrl:'on-site-consultation', enabledActions: [ 'search', 'delete', 'hide-delete-action']}"
    />
  </v-container>
  
</template>



<script>
import HyphenDatatable from "./../component/HyphenDatatable.vue"

export default {
  data() {
    return {
      headers:[
                    {text: 'Id', value: 'id'},
                    {text: 'Title', value: 'title'},
                    {text: 'Inquiry Type', value: 'inquiryType'},
                    {text: 'Area', value: 'area'},
                    {text: 'Preferred Date', value: 'preferredDate'},
              
                    {text: 'Approved Date', value: 'approvedDate'},
                    {text: 'Customer', value: 'customer.name'},
                    {text: 'Status', value: 'status'},
            ],
    }
  },

  methods: {

    handleClick(args){
            this.$router.push({path:'on-site-consultation/'+args.id});
          console.log(args);
        //   this.$router.push({path:"/design/"+args.id});
        
        
        
      },
  },
  mounted() {
    // this.getOnSiteConsultationList();
  },
  components:{
    HyphenDatatable
  }
}
</script>


<style scoped></style>
